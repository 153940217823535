import {
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ContactUS from "../src/images/Rectangle 4311.svg";
import Footer from "./Footer";

export default function Contact() {
  const backgroundStyle = {
    background: `
              radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px),
              radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px)
            `,
    backgroundSize: "30px 30px",
    backgroundPosition: "20px ,20px, 20px 20px",
    minHeight: "100vh",
    padding: "20px",
  };

  return (
    <>
      <div style={backgroundStyle}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={10} lg={12}>
            <Card
              sx={{
                bgcolor: "#F9F9F9",
                borderRadius: 5,
                padding: { xs: 2, md: 3 },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} padding={2} mt={8}>
                  <Typography
                    color={"#1F2870"}
                    variant="h3"
                    fontFamily={"sans-serif"}
                    gutterBottom
                  >
                    Register Your Business and Get Started
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"#5F5E61"}
                    fontFamily={"sans-serif"}
                    gutterBottom
                  >
                    Ready to transform your business's financial journey? Sign
                    up with AssuredPay and unlock the power of secure payments
                    and dynamic fund flow management. Take the first step toward
                    a stress-free financial future.
                  </Typography>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="E-Mail Address"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Country" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="City" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="State/Region"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Zip/Code"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Company" variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Role" variant="standard" fullWidth />
                    </Grid>
                  </Grid>
                  <Stack mt={5} alignItems="center">
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 20,
                        bgcolor: "#303981",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    src={ContactUS}
                    alt="Contact"
                    style={{ width: "100%", padding: 2 }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid width={"100%"}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
