import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FooterLogo from "../src/images/Logo (1).svg";

export default function Footer() {
  return (
    <div>
      <Box bgcolor={"#303981"} padding={5} mt={5}>
        <img src={FooterLogo} alt="footerLogo" style={{ marginBottom: 5 }} />
        <Grid container spacing={3} justifyContent={"space-between"}>
          <Grid item xs={12} md={3}>
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={"white"}
                fontFamily={"sans-serif"}
              >
                Menu
              </Typography>
              <Link href="/home" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Home
                </Typography>
              </Link>
              <Link
                href="/problem-and-solution"
                color="inherit"
                underline="none"
              >
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Problem & Solution
                </Typography>
              </Link>
              <Link href="/contact" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Contact/Login
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={"white"}
                fontFamily={"sans-serif"}
              >
                Help
              </Typography>
              <Link href="/about-us" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  About US
                </Typography>
              </Link>
              <Link href="/" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Support
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={"white"}
                fontFamily={"sans-serif"}
              >
                Legal Links
              </Typography>
              <Link href="/" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link href="/" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Terms & Services
                </Typography>
              </Link>
              <Link href="/" color="inherit" underline="none">
                <Typography
                  variant="subtitle1"
                  color={"white"}
                  fontFamily={"sans-serif"}
                >
                  Disclaimer
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                maxWidth: "100%",
                bgcolor: "#121949",
                borderRadius: 5,
                padding: 3,
                color: "white",
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#FA8334",
                      borderRadius: "20px",
                    },
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#FA8334",
                    },
                  "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#FA8334",
                    },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          bgcolor: "#FA8334",
                          borderRadius: "20px",
                          color: "#fff",
                          "&:hover": {
                            bgcolor: "#FA8334",
                          },
                        }}
                      >
                        Subscribe
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="h6" mt={2} fontFamily={"sans-serif"}>
                Subscribe for Exclusive Updates
              </Typography>
              <Typography variant="caption" fontFamily={"sans-serif"}>
                Stay ahead with AssuredPay. Subscribe now for the latest
                insights, offers, and product updates. Unlock financial control
                today.
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 3, bgcolor: "white" }} />
        <Stack flexDirection={"row"} justifyContent={"space-between"} mt={2}>
          <Typography color={"white"}>
            Copyright @2023 Aspire. All Rights Reserved.
          </Typography>
          <Typography color={"white"}>
            Terms & Conditions ~ Privacy Policy
          </Typography>
        </Stack>
      </Box>
    </div>
  );
}
