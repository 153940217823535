import React from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
} from "@mui/material";
import Home1 from "../src/images/Assured pay image.png";
import Star from "../src/images/material-symbols_award-star-rounded.svg";
import Image1 from "../src/images/image 12 (3).svg";
import Image33 from "../src/images/Frame 33 (3).svg";
import Image34 from "../src/images/Frame 34.svg";
import Image2 from "../src/images/image 12 (4).svg";
import Image35 from "../src/images/Frame 35.svg";
import happy from "../src/images/ion_happy.svg";
import vector from "../src/images/Vector (3).svg";
import verified from "../src/images/material-symbols_verified.svg";
import Vector4 from "../src/images/Vector (4).svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Component1 from "../src/images/Component 5.svg";
import Component2 from "../src/images/Component 5 (1).svg";
import Component3 from "../src/images/Component 5 (2).svg";
import Footer from "./Footer";

const Home = () => {
  const backgroundStyle = {
    background: `
      radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px),
      radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px)
    `,
    backgroundSize: "30px 30px",
    backgroundPosition: "20px, 20px",
    minHeight: "100vh",
    padding: "20px",
  };

  const faqs = [
    {
      question: "How does AssuredPay assure on-time payments?",
      answer:
        "AssuredPay employs cutting-edge technology and strategic partnerships to ensure that payments for your credit sales are received on time. We've designed a robust system that minimizes delays and disruptions, offering you the assurance you deserve.",
    },
    {
      question: "Can I use AssuredPay for my specific industry?",
      answer:
        "AssuredPay employs cutting-edge technology and strategic partnerships to ensure that payments for your credit sales are received on time. We've designed a robust system that minimizes delays and disruptions, offering you the assurance you deserve.",
    },
    {
      question: "What are the advantages of predictive fund visibility??",
      answer:
        "AssuredPay employs cutting-edge technology and strategic partnerships to ensure that payments for your credit sales are received on time. We've designed a robust system that minimizes delays and disruptions, offering you the assurance you deserve.",
    },
    {
      question: "How do I get started with AssuredPay?",
      answer:
        "AssuredPay employs cutting-edge technology and strategic partnerships to ensure that payments for your credit sales are received on time. We've designed a robust system that minimizes delays and disruptions, offering you the assurance you deserve.",
    },
  ];

  return (
    <div style={backgroundStyle}>
      <Box
        sx={{
          minHeight: "90vh",
          display: "flex",
          mt: 8,
        }}
      >
        <img
          src={Home1}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <Container maxWidth="md">
          <Stack spacing={2} alignItems="center" mt={7}>
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
              sx={{
                fontSize: {
                  xs: "h5.fontSize",
                  sm: "h4.fontSize",
                  md: "h3.fontSize",
                },
              }}
            >
              Unleash Financial Strength &
            </Typography>
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
              sx={{
                fontSize: {
                  xs: "h5.fontSize",
                  sm: "h4.fontSize",
                  md: "h3.fontSize",
                },
              }}
            >
              Freedom for{" "}
              <img src={Star} alt="Star" style={{ verticalAlign: "middle" }} />
              Your Business
            </Typography>
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
              sx={{
                fontSize: {
                  xs: "h5.fontSize",
                  sm: "h4.fontSize",
                  md: "h3.fontSize",
                },
              }}
            >
              with AssuredPay
            </Typography>
            <Typography
              variant="body2"
              color={"#5F5E61"}
              fontFamily={"sans-serif"}
              textAlign="center"
              sx={{ width: { xs: "100%", sm: 500 } }}
            >
              Say goodbye to collection & payment delays forever, leading to
              working capital stress and mismanagement. Make AssuredPay your
              trusted partner for payment assurance and seamless fund flow
              management.
            </Typography>
            <Stack flexDirection={"row"} gap={2} mt={2}>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "white",
                  bgcolor: "#1F2870",
                  fontFamily: "sans-serif",
                  width: { xs: "80%", sm: 150 },
                  height: { xs: 50, sm: 70 },
                  fontWeight: 200,
                  "&:hover": {
                    bgcolor: "#1F2870",
                  },
                }}
                variant="contained"
              >
                Get Started
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "70vh",
          overflow: "hidden",
        }}
      >
        <img
          src={Image1}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mb: 4,
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
            p: { xs: 1, sm: 2, md: 3 },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily={"sans-serif"}
            sx={{
              flexBasis: { xs: "100%", md: "auto" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Why Choose us
          </Typography>
          <Divider
            sx={{
              flexGrow: 1,
              borderWidth: 1,
              borderColor: "#9CA5F5",
              width: { xs: "95%", md: "80%" },
              my: { xs: 2, md: 0 },
            }}
          />
        </Stack>
        <Grid
          container
          spacing={4}
          sx={{
            position: "relative",
            zIndex: 1,
            padding: "0 16px",
            mt: 8,
            justifyContent: { xs: "space-between", md: "space-between" },
          }}
        >
          <Grid item xs={12} md={4}>
            <Typography
              variant="h3"
              fontWeight={200}
              color="#1F2870"
              fontFamily="sans-serif"
              width={{ xs: "100%", md: "70%" }}
            >
              Assured On-Time Payments
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <img src={Image33} alt="Image33" style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              color="#46464E"
              fontFamily="sans-serif"
              width={{ xs: "100%" }}
            >
              Our core commitment is to ensure your on time collections for all
              your credit sales, eliminating the uncertainties and disruptions
              in your business caused due delayed collections. AssuredPay
              instills confidence in your financial planning.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "50vh",
          overflow: "hidden",
          mt: 8,
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            position: "relative",
            zIndex: 1,
            padding: "0 16px",
            mt: 8,
            justifyContent: { xs: "space-between", md: "space-between" },
          }}
        >
          <Grid item xs={12} md={4}>
            <Typography
              variant="h3"
              fontWeight={200}
              color="#1F2870"
              fontFamily="sans-serif"
              width={{ xs: "100%", md: "70%" }}
            >
              Innovative Fund Flow Management:
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <img src={Image34} alt="Image34" style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              fontWeight={200}
              color="#1F2870"
              fontFamily="sans-serif"
              width={{ xs: "100%", md: "70%" }}
            >
              Innovative Fund Flow Management :
            </Typography>
            <Typography
              color="#46464E"
              fontFamily="sans-serif"
              width={{ xs: "100%" }}
            >
              AssuredPay empowers you to optimize cash flow by utilizing your
              future receivables to book payments. This innovative approach
              allows you to unlock liquidity, providing the flexibility needed
              for business expansion and strategic investments.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "75vh",
          overflow: "hidden",
        }}
      >
        <img
          src={Image2}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            maxWidth: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />
        <Grid
          container
          spacing={4}
          sx={{
            position: "relative",
            zIndex: 1,
            padding: "0 16px",
            mt: 15,
            justifyContent: { xs: "space-between", md: "space-between" },
          }}
        >
          <Grid item xs={12} md={4}>
            <Typography
              variant="h3"
              fontWeight={200}
              color="#1F2870"
              fontFamily="sans-serif"
              width={{ xs: "100%", md: "70%" }}
            >
              Real-Time Fund Visibility:
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <img src={Image35} alt="Image35" style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              fontWeight={200}
              color="#1F2870"
              fontFamily="sans-serif"
              width={{ xs: "100%", md: "70%" }}
            >
              Real-Time Fund Visibility:
            </Typography>
            <Typography
              color="#46464E"
              fontFamily="sans-serif"
              width={{ xs: "100%" }}
            >
              Get a comprehensive, real time view of your payments and
              receivables. With AssuredPay’s advanced analytical tool, you can
              access your rich business data and draw insights to take informed
              financial decisions. This visibility enables you to stay ahead of
              market trends and navigate you through financial challenges
              effectively, boosting and optimising your overall fund
              utilisation. AssuredPay goes beyond payment surety and traditional
              methods of fund flow management. Our powerful platform offers
              additional features to help your business thrive:
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 4,
          mt: { xs: 4, sm: 6, md: 8, lg: 10 },
          p: { xs: 1, sm: 2, md: 3 },
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          fontFamily={"sans-serif"}
          sx={{
            flexBasis: { xs: "100%", md: "auto" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Benefits of Using AssuredPay
        </Typography>
        <Divider
          sx={{
            flexGrow: 1,
            borderWidth: 1,
            borderColor: "#9CA5F5",
            width: { xs: "95%", md: "60%" },
            my: { xs: 2, md: 0 },
          }}
        />
      </Stack>
      <Box sx={{ mt: 6, p: 3 }}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 5,
                border: "1px solid #9CA5F5",
              }}
            >
              <img src={happy} alt="happy" />
              <Typography
                variant="h5"
                color={"#303981"}
                fontFamily={"sans-serif"}
                mt={3}
                width={180}
              >
                Financial Peace of Mind
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                mt={3}
                width="100%"
              >
                With AssuredPay, you can bid farewell to the stress and
                uncertainties of late payments and working capital constraints.
                Focus on your business's core activities with the assurance that
                your payments will be prompt and predictable.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 5,
                border: "1px solid #9CA5F5",
              }}
            >
              <img src={vector} alt="vector" />
              <Typography
                variant="h5"
                color={"#303981"}
                fontFamily={"sans-serif"}
                mt={2}
                width={120}
              >
                Enhanced Cash Flow
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                mt={1}
                width="100%"
              >
                AssuredPay's innovative approach to fund flow management
                empowers you to use your receivables strategically. This
                optimization enhances your cash flow, allowing you to seize
                growth opportunities, invest in innovation, and manage daily
                operations with ease.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 5,
                border: "1px solid #9CA5F5",
              }}
            >
              <img src={verified} alt="verified" />
              <Typography
                variant="h5"
                color={"#303981"}
                fontFamily={"sans-serif"}
                mt={2}
                width={200}
              >
                Access to Quality Verified Parties
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                mt={10}
                width="100%"
              >
                Grow your business exponentially by connecting with new
                trustworthy businesses on the platform.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 6, p: 3 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={5}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 5,
                border: "1px solid #9CA5F5",
              }}
            >
              <img src={Vector4} alt="Vector4" />
              <Typography
                variant="h5"
                color={"#303981"}
                fontFamily={"sans-serif"}
                mt={2}
              >
                Informed Decision-Making
              </Typography>
              <Typography color="#46464E" fontFamily="sans-serif" mt={2}>
                Our predictive insights give you a competitive edge. Data-driven
                decisions based on real-time financial information help you stay
                agile in a dynamic market. AssuredPay equips you with the tools
                needed to make informed choices that align with your business
                goals.
              </Typography>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  color: "#121C66",
                  borderColor: "#121C66",
                  borderRadius: 10,
                  mt: 1,
                  padding: { xs: 1, sm: 2 },
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", sm: 150 },
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Get Started
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ bgcolor: "#F1EFFF", borderRadius: 4, m: 2, p: { xs: 2, md: 4 } }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mb: 4,
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
            p: { xs: 1, sm: 2, md: 1 },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily={"sans-serif"}
            sx={{
              flexBasis: { xs: "100%", md: "auto" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Divider
            sx={{
              flexGrow: 1,
              borderWidth: 1,
              borderColor: "#9CA5F5",
              width: { xs: "95%", md: "70%" },
              my: { xs: 2, md: 0 },
            }}
          />
        </Stack>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            sx={{
              bgcolor: "#F1EFFF",
              color: "#9CA5F5",
              mb: 1,
              "&:before": { display: "none" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#9CA5F5" }} />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              sx={{ bgcolor: "#F1EFFF", p: 3 }}
            >
              <Typography
                variant="h6"
                sx={{ bgcolor: "#F1EFFF", color: "#303981", fontWeight: 600 }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ bgcolor: "#F1EFFF", color: "#303981" }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 4,
          mt: { xs: 4, sm: 6, md: 8, lg: 10 },
          p: { xs: 1, sm: 2, md: 1 },
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          fontFamily={"sans-serif"}
          sx={{
            flexBasis: { xs: "100%", md: "auto" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          READ OUR BLOGS
        </Typography>
        <Divider
          sx={{
            flexGrow: 1,
            borderWidth: 1,
            borderColor: "#9CA5F5",
            width: { xs: "95%", md: "70%" },
            my: { xs: 2, md: 0 },
          }}
        />
      </Stack>
      <Typography color="#46464E" fontFamily="sans-serif" mt={2}>
        Explore our blog for a treasure trove of insights on financial
        management, payment assurance, and strategies for business growth. Our
        team of experts regularly publishes articles that provide valuable
        guidance and keep you informed about the latest developments in the
        financial world.
      </Typography>

      <Stack
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-around"}
        spacing={2}
        mt={4}
      >
        <img src={Component1} alt="Component1" />
        <img src={Component2} alt="Component2" />
        <img src={Component3} alt="Component3" />
      </Stack>
      <Footer />
    </div>
  );
};

export default Home;
