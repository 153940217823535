import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Image13 from "../src/images/image 13.svg";
import Image4310 from "../src/images/Rectangle 4310.svg";
import Question from "../src/images/ph_question.svg";
import card9611 from "../src/images/Group 9619.svg";
import Image12 from "../src/images/image 12 (1).svg";
import Number1 from "../src/images/01.svg";
import Icon59 from "../src/images/Frame 59 (1).svg";
import Icon60 from "../src/images/Frame 60 (1).svg";
import Number2 from "../src/images/02.svg";
import Number3 from "../src/images/03.svg";
import Back1 from "../src/images/image 12 (2).svg";
import Image63 from "../src/images/Frame 63 (2).svg";
import Footer from "./Footer";
import FooterLogo from "../src/images/Logo (1).svg";

export default function Problem() {
  const backgroundStyle = {
    background: `
          radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px),
          radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px)
        `,
    backgroundSize: "30px 30px",
    backgroundPosition: "20px ,20px, 20px 20px",
    minHeight: "100vh",
    padding: "20px",
  };
  return (
    <>
      <div style={backgroundStyle}>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 15,
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
            >
              AssuredPay’s Tailored
            </Typography>
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
            >
              Solutions for MSMEs
            </Typography>
            <Typography
              variant="caption"
              color={"#5F5E61"}
              fontFamily={"sans-serif"}
              textAlign="center"
              pl={4}
            >
              Understanding the Financial Challenges Faced by Indian MSMEs
            </Typography>
            <Stack flexDirection={"row"} gap={2}>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#E4E5FF",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#E4E5FF",
                  },
                }}
                variant="contained"
                size="small"
              >
                Financial Peace
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#FFD8C2",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#FFD8C2",
                  },
                }}
                variant="contained"
              >
                Fund Flow Management
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#E4E1E9",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#E4E1E9",
                  },
                }}
                variant="contained"
              >
                Fund Visibility
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
            mt: 18,
          }}
        >
          <img
            src={Image12}
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
              mb: 4,
              mt: { xs: 4, sm: 6, md: 8, lg: 10 },
              p: { xs: 1, sm: 2, md: 3 },
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontFamily={"sans-serif"}
              sx={{
                flexBasis: { xs: "100%", md: "auto" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Problem and Solution
            </Typography>
            <Divider
              sx={{
                flexGrow: 1,
                borderWidth: 1,
                borderColor: "#9CA5F5",
                width: { xs: "80%", md: "50%" },
                my: { xs: 2, md: 0 },
              }}
            />
          </Stack>
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              padding: "0 16px",
              mt: 8,
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Image4310}
                alt="Image33"
                style={{ width: "100%", maxWidth: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  p: { xs: 2, md: "7px 200px 3px 8px" },
                  borderRadius: 2,
                  bgcolor: "#FFFFFF",
                  height: 250,
                }}
              >
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#1F2870",
                    bgcolor: "#FFD8C2",
                    fontFamily: "sans-serif",
                    fontWeight: 550,
                    "&:hover": {
                      bgcolor: "#FFD8C2",
                    },
                  }}
                  variant="contained"
                >
                  <img src={Question} alt="Question" /> The Problem Statements
                </Button>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  width="100%"
                  mt={4}
                >
                  Micro, Small, and Medium Enterprises (MSMEs) are the backbone
                  of the Indian economy, driving growth and providing
                  employment. However, these businesses often grapple with a
                  range of financial challenges:
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  p: 1,
                  borderRadius: 2,
                  bgcolor: "#E0E3FF",
                  height: 250,
                }}
              >
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#1F2870",
                    bgcolor: "#F2FBD7",
                    fontFamily: "sans-serif",
                    fontWeight: 550,
                    "&:hover": {
                      bgcolor: "#F2FBD7",
                    },
                  }}
                  variant="contained"
                >
                  <img src={card9611} alt="Question" /> AssuredPay's Tailored
                  Solutions:
                </Button>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  width="100%"
                  mt={6}
                >
                  Empowering MSMEs for Financial Success At AssuredPay, we've
                  made it our mission to address these pressing challenges and
                  provide tailored solutions designed specifically for Indian
                  MSMEs.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              padding: "0 16px",
              mt: 14,
              justifyContent: { xs: "space-between", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Number1}
                alt="Number1"
                style={{ width: "100%", maxWidth: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon59} alt="Icon59" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "50%" }}
              >
                Working Capital Stress:
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                MSMEs are in a constant struggle to secure the working capital
                required for their daily operations and business expansion with
                credit sales. This financial stress can significantly impact
                their ability to meet business demands and capitalize on growth
                opportunities.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  width: { xs: "65%", md: "80%" },
                  my: { xs: 2, md: 10 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon60} alt="Icon60" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "90%" }}
              >
                Assured Payments for Financial Peace:
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                AssuredPay provides a strategic solution by ensuring on-time
                payments for credit sales. Our platform ensures that payments
                are received promptly, alleviating the stress associated with
                late or unrealised payments / bad debts. This financial security
                empowers businesses to operate with confidence and expand their
                businesses without any stress.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  borderColor: "#FA8334",
                  width: { xs: "75%", md: "80%" },
                  my: { xs: 2, md: 3 },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              mt: 5,
              padding: "0 20px",
              justifyContent: { xs: "space-between", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Number2}
                alt="Number2"
                style={{ width: "100%", maxWidth: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon59} alt="Icon59" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "50%" }}
              >
                Payment Delays:
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                Delay in receiving payments from Customers, is a common pain
                point for the majority of businesses. These delays disrupt cash
                flow, increases cost of recovery from Customers, strain
                relationships with suppliers and employees and hinder financial
                stability.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  width: { xs: "65%", md: "80%" },
                  my: { xs: 2, md: 13 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon60} alt="Icon60" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "100%" }}
              >
                Innovative & Superior Fund Flow Management With Assured
                Receivables :
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                We empower MSMEs to leverage their future receivables to book
                payments, offering an innovative approach to superior cash flow
                management and improving the overall financial health of the
                business. This optimisation of cash flow provides the liquidity
                needed for business expansion. AssuredPay's solution puts
                businesses in control of their finances.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  borderColor: "#FA8334",
                  width: { xs: "75%", md: "80%" },
                  my: { xs: 2, md: 3 },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
          }}
        >
          <img
            src={Back1}
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              mt: 3,
              padding: "0 16px",
              justifyContent: { xs: "space-between", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Number3}
                alt="Number3"
                style={{ width: "100%", maxWidth: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon59} alt="Icon59" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "50%" }}
              >
                Fund Flow Invisibility:
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                Many MSMEs lack real-time visibility into their fund flows. This
                financial blind spot makes it challenging to plan and allocate
                resources effectively, leading to uncertainty and risk.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  width: { xs: "65%", md: "80%" },
                  my: { xs: 2, md: 15 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={Icon60} alt="Icon60" />
              <Typography
                variant="h5"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "100%" }}
              >
                Predictive Fund Visibility for Informed Decisions:
              </Typography>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                AssuredPay equips MSMEs with predictive tools that offer
                real-time visibility into payments and receivables. With this
                data-driven insight, businesses can make informed financial
                decisions, proactively manage resources and navigate financial
                challenges with ease.Join AssuredPay today and discover how our
                tailored solutions can empower your MSME to overcome financial
                obstacles and achieve sustainable growth.
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  borderColor: "#FA8334",
                  width: { xs: "75%", md: "80%" },
                  my: { xs: 2, md: 3 },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "30vh",
            overflow: "hidden",
            borderRadius: 15,
            p: { xs: 0, sm: 4, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mx: "auto",
            maxWidth: "1200px",
            mt: { xs: 8, sm: 10, md: 15 },
            mb: { xs: 8, sm: 10, md: 15 },
          }}
        >
          <img
            src={Image63}
            alt="Background"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 50 }}
            alignItems="center"
            justifyContent="center"
            mb={{ xs: 2, sm: 3 }}
            p={{ xs: 2, sm: 4 }}
          >
            <Typography
              variant="h3"
              fontWeight={700}
              color="white"
              fontFamily="sans-serif"
              textAlign="center"
            >
              Join Us
            </Typography>
            <img
              src={FooterLogo}
              alt="FooterLogo"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Stack>

          <Card
            sx={{
              bgcolor: "#424B8D",
              width: "90%",
              maxWidth: "800px",
              p: { xs: 2, sm: 3, md: 4 },
              mb: { xs: 2, sm: 4, md: 6 },
              textAlign: "center",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="white"
                fontFamily="sans-serif"
                width={{ xs: "100%", sm: "70%" }}
                p={2}
              >
                Many MSMEs lack real-time visibility into their fund flows. This
                financial blind spot makes it challenging to plan and allocate
                resources effectively, leading to uncertainty and risk.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FA8334",
                  borderRadius: 10,
                  padding: { xs: 1, sm: 2 },
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", sm: 200 },
                  "&:hover": {
                    bgcolor: "#FA8334",
                    opacity: 1,
                  },
                }}
              >
                Get Started
              </Button>
            </Stack>
          </Card>
        </Box>
        <Footer />
      </div>
    </>
  );
}
